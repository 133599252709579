import axios from 'axios'
import configClient from '../../../../../../client/config.client'
import { history } from '../../../../../history'
import { addEvent, clearTemporaryEvent } from '../../../../../api/app/events'
import { loadUserEntities, setAuthenticatedUser } from '../../../../../api/app/auth'
import { addFlashMessage } from '../../../../../api/app/behaviors/flash-messages'
import { getRecipients } from '@vidday/utils'
import { logAddToCartEvent } from '@vidday/utils'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: configClient.api.baseUrl,
})

const checkSendgridInvalidity = (verification) => {
	if (verification && verification.result) {
		const { verdict, checks } = verification.result

		return (
			!verdict === 'Valid' ||
			!verdict === 'Risky' ||
			verdict === 'Invalid' ||
			!checks?.domain?.has_mx_or_a_record ||
			!checks?.domain?.has_valid_address_syntax ||
			checks?.domain?.is_suspected_disposable_address ||
			checks?.additional?.has_known_bounces
		)
	} else return true
}

const checkReacherInvalidity = (verification) => {
	if (verification) {
		const { mx, syntax, is_reachable, misc } = verification

		return !mx?.accepts_mail || !syntax?.is_valid_syntax || is_reachable === 'invalid' || misc?.is_disposable
	} else return true
}

export function loginUserSuccess(res, dispatch, redirect = '/dashboard', temporaryEvent) {
	let message = res.message || 'Login successful.'

	if (temporaryEvent) {
		temporaryEvent.creatorId = res.data.id ? res.data.id : null

		dispatch(addEvent(temporaryEvent)).then((response) => {
			// Get data of returned event entity
			let eventData = Object.values(response.payload.entities.events)[0]
			// remove temporaryEvent event
			dispatch(clearTemporaryEvent())
			// redirect to event page, temporaily logged in, still awaiting account activation.
			history.push(`/event/${eventData.uuid}?auth=email&addtocart=true`)
		})
	} else {
		if(redirect === '/dashboard'){
			redirectToNextIfWindow('/dashboard')
		}
		else {
			history.push(redirect)
			// dispatch(push(redirect))
		}
	}
	// dispatch setauthenticatesuser
	dispatch(setAuthenticatedUser(res.data, res.message))
	// load authenticated user entities
	dispatch(loadUserEntities(res.data.id))
	// send flash for sucess
	dispatch(addFlashMessage('success', message))
}

export const createEvent = async (data) => {
	try {
		const response = await instance.post('/event', data)
		// Retrieve event from response
		const event = response.data.data
		// Format recipients
		const recipients = getRecipients(event.recipients, false).names
		// Let's log the event to cart
		logAddToCartEvent({ event: event, recipients })
		return { success: true, data: response.data }
	} catch (error) {
		if (error.response) {
			const status = error.response.status,
				message = error.response.data?.message,
				reason = error.response.data?.reason
			return { success: false, status, message, reason }
		}

		return { success: false, status: 500, message: 'Create Event Error.' }
	}
}

export const loginUser = async (data) => {
	try {
		const response = await instance.post('/v2/auth/login', data)
		return { success: true, data: response.data }
	} catch (error) {
		/** We have a response from the server, but wasn't successful */
		if (error.response) {
			const status = error.response.status,
				message = error.response.data?.message,
				reason = error.response.data?.reason

			const err = { success: false, status, message }

			/** User exist, but password is wrong */
			if (status == 401) {
				return { ...err, reason: reason }
			} else if (status == 404) {
				/** User doesn't exist */
				return { ...err, reason: reason }
			} else if (status == 400) {
				/** Email or password issue */
				/** Account exist but has not been activated yet */
				if (reason == 'ACTIVATION_REQUIRED') {
					return { ...err, reason: reason }
				} else if (reason == 'ACCOUNT_BLOCKED') {
					/** Account has been blocked */
					return { ...err, reason: reason }
				}
				/** Email or password missing in the request */
				return { ...err, message: 'Bad request, email or password missing.' }
			} else if (status == 500) {
				/** Server error */
				return { ...err, status: 400, message: 'Unkown backend error.' }
			}
		}

		/** Unknow error, catch all */
		return { success: false, status: 500, message: 'Unkown error.' }
	}
}

export const registerUserSuccess = (res, dispatch, redirect, temporaryEvent) => {
	if (temporaryEvent) {
		temporaryEvent.creatorId = res.data.id ? res.data.id : null

		dispatch(addEvent(temporaryEvent)).then((response) => {
			// Get data of returned event entity
			let eventData = Object.values(response.payload.entities.events)[0]
			// remove temporaryEvent event
			dispatch(clearTemporaryEvent())
			// redirect to event page, temporaily logged in, still awaiting account activation.
			history.push(`/event/${eventData.uuid}?auth=email&addtocart=true`)
		})
	} else {
		history.push(redirect)
		// dispatch(push(redirect))
	}
}

export const registerUser = async (data) => {
	try {
		const response = await instance.post('/v2/auth/register', data)
		return { success: true, data: response.data }
	} catch (error) {
		if (error.response) {
			const status = error.response.status,
				message = error.response.data?.message,
				reason = error.response.data?.reason

			const err = { success: false, status: status, message: message }

			if (status == 400) {
				if (reason == 'ACCOUNT_EXISTS') {
					return { ...err, message: 'This account already exist.', reason: reason }
				} else {
					return { ...err, message: 'Registration failed.' }
				}
			}
		}

		return { success: false, status: 500, message: 'Register User Error.' }
	}
}

export const checkAccount = async (email) => {
	try {
		const response = await instance.post('/v2/auth/check-user', { email: email })

		/** If the account already exist */
		if (response.data.success == false) {
			return {
				success: true,
				reason: 'ACCOUNT_EXISTS',
				data: response.data.data,
				message: response.data.message,
			}
		} else {
			return {
				success: true,
				message: response.data.message,
				reason: 'ACCOUNT_AVAILABLE',
			}
		}
	} catch (error) {
		return { success: false, message: error.response.data.message }
	}
}

const whitelist = [
	'aol.com',
	'hotmail.com',
	'verizon.net',
	'icloud.com',
	'me.com',
	'outlook.com',
	'ar-sm.co.uk',
	'gmail.com',
	'rogers.com',
	'lighthouse9.ca',
	'ymail.com',
	'umn.edu',
	'yahoo.com',
	'yahoo.ca',
	'yahoo.de',
	'yahoo.co.uk',
	'shaw.ca',
	'cfacorp.com',
]

const defaultConfig = {
	provider: 'reacher',
	source: null,
	timeout: null,
}
/**
 * Verify that an email is valid, using a given config object
 * @param {*} email
 * @param {*} userConfig
 * @returns
 */
export const verifyEmail = async (email, userConfig = defaultConfig) => {
	// Merge config object
	const postConfig = { ...defaultConfig, ...userConfig }

	try {
		const response = await instance.post('/v2/mail/verify', {
			email,
			...postConfig,
		})

		const val = response.data.data.input

		if (response.data?.data) {
			let data = response.data?.data
			let isInvalid

			// Check if email is valid:
			if (postConfig.provider == 'reacher') {
				isInvalid = checkReacherInvalidity(data)
			} else if (postConfig.provider == 'sendgrid') {
				isInvalid = checkSendgridInvalidity(data)
			}

			// Despite being invalid, let's run some additional whitelisting to make sure we can't allow it.
			if (isInvalid) {
				// First, let all timeouts resolve to being acceptable
				if (data.is_reachable == 'timeout') {
					isInvalid = false
				}
				// Let's check the email against whitelist
				else {
					const domain = email.split('@')[1].trim()
					if (domain) {
						const isMatch = whitelist.findIndex((el) => el == domain)
						// If we've found a match, let go through
						if (isMatch != -1) {
							isInvalid = false
						}
					}
				}
			}

			// Do something here...
			return { isValid: !isInvalid, email: val || email }
		} else {
			// Retrun valid, accept email blindly
			return { isValid: true, email: email }
		}
	} catch (err) {
		// Retrun valid, accept email blindly
		return { isValid: true, email: email }
	}
}

export const getUserLocation = async () => {
	try {
		const res = await axios.get('https://ipapi.co/json/?key=Cp8CCYMhrP4YruOA3bOB9inKnEUai2j2zvwyygsTmB8kybWMOs')

		return { success: true, data: res.data }
	} catch (error) {
		return { success: false, error: 'Network Error.' }
	}
}
