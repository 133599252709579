import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import NavBar from '../../atoms/NavBar'
import { Flex, Container, useDisclosure, Image, Text, Heading, Button } from '@chakra-ui/react'
import BackButton from '../../atoms/nav-controls/BackButton'
import Card from '../../atoms/Card'
import { useEventContext } from '../../../contexts/EventProvider'
import ModalActivateEventPage from '../../organisms/ModalActivateEventPage'
import { Helmet } from 'react-helmet'
import PublicHeader from '../public/atoms/PublicHeader'
import FooterBase from '../../molecules/FooterBase'
import useRenderJobs from '../../../hooks/useRenderJobs'
import dayjs from 'dayjs'

import loadable from '@loadable/component'
import redirectToNextIfWindow from '../../../utils/redirectToNextIfWindow'
const RenderJobProgress = loadable(() => import(/* webpackPrefetch: true */ '../../molecules/render-job-progress'), {
	ssr: false,
})

const Publishing = () => {
	const navigate = useNavigate()
	const { event } = useEventContext()
	const renderJobs = useRenderJobs()

	// Function to select newest object based on createdAt key
	const selectLatestJob = (objects, eventId) => {
		// Return null if array is empty
		if (objects.length === 0) {
			return null
		}
		// Use only this event active renders aka not in finished state or a preview
		const jobs = objects.filter((e) => e.eventId == eventId && e.jobState != 'finished' && !e.preview)
		// Sort the array of objects based on createdAt in descending order
		jobs.sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf())
		// Return the first (newest) object from the sorted array
		return jobs[0]
	}

	const [latestRenderJob, setLatestRenderJob] = useState()
	useEffect(() => {
		setLatestRenderJob(selectLatestJob(renderJobs, event.id))
	}, [renderJobs])

	/** Activate event Modal */
	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<>
			<Helmet>
				<title>VidDay - Publishing...</title>
			</Helmet>

			<NavBar>
				<Flex>
					<BackButton
						onClick={() => {
							redirectToNextIfWindow('/dashboard')
							// navigate('/dashboard')
						}}
					/>
				</Flex>
			</NavBar>

			<PublicHeader />

			<Container maxW="container.xl" py="1rem" mt="2rem">
				<Card px="1rem" py="2rem" alignItems="center">
					{latestRenderJob && latestRenderJob.jobState == 'error' ? (
						<>
							<Image src={'/assets/images/pages/publishing/final-render-job-error.svg'} mb="1rem" />
							<Heading size={'sm'}>There's a small snag with your video, but don't worry.</Heading>
							<Text maxW="650px" textAlign="center">
								Our team is actively fixing it. Need your video urgently? Please start a chat, and we'll
								prioritize your request.
							</Text>
						</>
					) : (
						<RenderJobProgress />
					)}

					<Button mt="2rem" variant="solid" as="a" href="/dashboard">
						Dashboard
					</Button>
					<Text mt=".5rem" textAlign={'center'}>
						<small>You can leave this page at any time without affecting your video.</small>
					</Text>
				</Card>
			</Container>
			<FooterBase />

			<ModalActivateEventPage event={event} isOpen={isOpen} onClose={onClose} />
		</>
	)
}

export default Publishing
